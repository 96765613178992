import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#1156b2',
                secondary: '#ff9800',
                accent: '#673ab7',
                error: '#f44336',
                warning: '#ffeb3b',
                info: '#2196f3',
                success: '#8bc34a'
            },
            dark: {
                primary: '#1156b2',
                secondary: '#ff9800',
                accent: '#673ab7',
                error: '#f44336',
                warning: '#ffeb3b',
                info: '#2196f3',
                success: '#8bc34a'
            }
        }
    },
});
