import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/home/Index.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
      },
      {
        path: 'products',
        name: 'Products',
        component: () => import('@/views/Products.vue'),
      },
      {
        path: 'solution',
        name: 'Solution',
        component: () => import('@/views/Solution.vue'),
      },
      {
        path: 'blog',
        name: 'Blog',
        component: () => import('@/views/Blog.vue'),
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import('@/views/Contact.vue'),
      },

      {
        path: 'blog/iot-things-must-communicate-directly',
        name: 'iot-things-must-communicate-directly',
        component: () => import('@/views/blog/iot-things-must-communicate-directly.vue'),
      },
      {
        path: 'blog/why-60-pct-has-problems-scaling-iot',
        name: 'why-60-pct-has-problems-scaling-iot',
        component: () => import('@/views/blog/why-60-pct-has-problems-scaling-iot.vue'),
      },
      {
        path: 'blog/taylor-weber-and-iot-solutions',
        name: 'taylor-weber-and-iot-solutions',
        component: () => import('@/views/blog/taylor-weber-and-iot-solutions.vue'),
      },
      {
        path: 'blog/the-problem-with-current-iot-protocols',
        name: 'the-problem-with-current-iot-protocols',
        component: () => import('@/views/blog/the-problem-with-current-iot-protocols.vue'),
      },
      {
        path: 'blog/iot-architecture-is-10-years-behind',
        name: 'iot-architecture-is-10-years-behind',
        component: () => import('@/views/blog/iot-architecture-is-10-years-behind.vue'),
      },

      {
        path: '*',
        name: 'FourOhFour',
        component: () => import('@/views/404.vue'),
      },
    ],
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
