<template>

<!--
  <v-app id="aernetworks">
    <v-app-bar
      app
      color="white"
      flat
    >
      <v-container class="py-0 fill-height">
        <v-app-bar-nav-icon
          link :to="{name: 'home'}" exact
          color="white"
        >
          <v-img
            :src="require('./assets/logo.svg')"
            contain
            height="32"
          />
        </v-app-bar-nav-icon>
        <v-app-bar-title
          link :to="{name: 'home'}" exact
          class="ml-4"
        >
          aernetworks
        </v-app-bar-title>

        <v-spacer></v-spacer>

        <v-btn text link :to="{name: 'products'}" class="mx-2">
          Products
        </v-btn>
        <v-btn text link :to="{name: 'about'}" class="mx-2">
          about
        </v-btn>
        <v-btn text link :to="{name: 'support'}" class="mx-2">
          Support
        </v-btn>


      </v-container>
    </v-app-bar>

    <v-main>
    </v-main>
  </v-app>
-->
  <router-view/>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

